<template>
    <div>
        <div class="search-bar-box">
            <slot name="search-bar"></slot>
        </div>

        <div class="edc-table-box">
            <!--@sort-change='sortChange'  自定义排序-->
            <!--@row-dblclick="singleSelectOk" 表格单选数据操作-->
            <!--@current-change="handleSinleSelection" 表格单选数据操作-->
            <!--@selection-change="handleMultSelection" 表格选择数据操作-->
            <!--:highlight-current-row="highCurrent"  开启单选功能-->
            <el-table :data="tableData"
                      border :row-key="getRowKeys" ref="baseTable" align="center"
                      :max-height="maxHeight" stripe>
                <!--多选checkbox-->
                <!--<el-table-column v-if="showMultCheckBox" :reserve-selection="true" type="selection"-->
                <!--width="40"></el-table-column>-->
                <!--表格数据-->

                <el-table-column v-for="(e,i) in tableColunm" :key="i" :show-overflow-tooltip="e.hideMore"
                                 :sort-orders="['ascending', 'descending']" :prop="e.prop" align="center"
                                 :fixed="e.fixed"
                                 :sortable="e.sort"
                                 :label="e.label" :width="e.width">
                    <template slot-scope="scope">
                        <span v-if="filterDict(e.prop)">
                        {{ scope.row[e.prop]|convertDict(e.prop,dict,scope.row[e.prop]) }}
                        </span>
                        <span v-else>
                        {{ scope.row[e.prop]}}
                        </span>
                    </template>
                </el-table-column>

                <!--按钮槽位-->
                <slot name="table-button">

                </slot>

            </el-table>

            <div :style="paginationStyle">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :page-size="pagesize" :page-sizes="pageSizes" :current-page="currentPage"
                               :layout="pageLayout"
                               :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            paginationStyle: {
                default() {
                    return {
                        "text-align": "right", "margin-top": "15px"
                    }
                }
            },
            maxHeight: {
                // default() {
                // return 46 * this.pagesize + 3;
                // }
            },
            tableData: {
                type: Array
            },
            tableColunm: {
                type: Array
            },
            dictFiled: {
                type: Array
            },
            dict: {},
            pageSizes: {
                type: Array,
                default: () => {
                    return [10, 20, 30];
                }
            },
            sortChange: {
                type: Function,
                default() {
                    return new Function();
                }
            },
            // showMultCheckBox: {
            //     type: Boolean,
            //     default: false
            // },
            // highCurrent: {
            //     type: Boolean,
            //     default: !this.showMultCheckBox,
            // },
            total: 0,
        },

        data() {
            return {
                pageLayout: 'total, sizes, prev, pager, next',//jumper
                getRowKeys(row) {
                    return row.id;
                },
                multSelectData: [],
                singleSelectData: {},
                currentPage: 1,
                pagesize: 10,
            }
        },
        filters: {
            convertDict(prop = '', str = '', dict = {}) {
                let dictList = dict[str];
                if (dictList && dictList.length)
                    for (let i = 0; i < dictList.length; i++)
                        if (dictList[i].value == prop)
                            return dictList[i].label;
                return prop;
            },
        },
        methods: {
            filterDict(type) {
                if (this.dictFiled != null)
                    return this.dictFiled.indexOf(type) >= 0;
                return false;
            },
            //每页数量变化
            handleSizeChange(val) {
                this.pagesize = val;
                this.$emit('handlePageChange', {size: this.pagesize, current: this.currentPage, totalNum: this.total});
            },
            //翻页
            handleCurrentChange(val) {
                this.currentPage = val;
                this.$emit('handlePageChange', {size: this.pagesize, current: this.currentPage, totalNum: this.total});
            },
            handleMultSelection(rows) {
                this.multSelectData = [];
                this.selectedIds = [];
                if (rows) {
                    rows.forEach(row => {
                        if (row) {
                            this.multSelectData.push(row);
                            this.selectedIds.push(row.id);
                        }
                    });
                }
            },

            handleSinleSelection(v) {
                this.singleSelectData = v;
            },
            singleSelectOk() {
                this.$emit('singleSelectOk', this.singleSelectData);
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>
    .search-bar-box {
        padding: 20px 0px 20px 40px;
    }

    .edc-table-box {
        padding: 0 40px 30px;

    }

    .default-pagination {
        position: fixed;
        z-index: 15;
        bottom: 24px;
        right: 32px;
    }

</style>