<template>
    <div>
        <div class="common-header">
            <div class="left-wrap" @click="$router.go(-1)">
                <i class="el-icon-arrow-left visit-back"></i><span class="visit-title">日志列表</span>
            </div>
        </div>
        <div>
            <EDCTable :table-data="tableData" :table-colunm="tableColunm"
                      :dict="dict" :dictFiled="dictFiled"
                      :total="page.totalNum"
                      @handlePageChange="handlePageChange"
                      ref="log_table">
                <div slot="search-bar" class="search-form-box">
                    <el-form :validate-on-rule-change="Boolean(false)" :model="searchForm" label-width="80px"
                             ref="searchForm" size="mini" label-position="left">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="研究版本" prop="title">
                                    <el-select style="width: 100%" v-model="searchForm.researchVersionCode">
                                        <el-option v-for="(e,i) in Object.keys(versionSelector)"
                                                   :label="versionSelector[e]"
                                                   :value="e">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" :push="2">
                                <el-form-item label="操作类型" prop="title">
                                    <el-select style="width: 100%" v-model="searchForm.operateType">
                                        <el-option v-for="(e,i) in dict.operateType" :label="e.label" :value="e.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" :push="4">
                                <el-form-item label="操作用户" prop="title">
                                    <el-input v-model="searchForm.userName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="操作环境" prop="title">
                                    <el-select style="width: 100%" v-model="searchForm.env">
                                        <el-option v-for="(e,i) in dict.env" :label="e.label" :value="e.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" :push="2">
                                <el-form-item label="日志类型" prop="title">
                                    <el-select style="width: 100%" v-model="searchForm.logType">
                                        <el-option v-for="(e,i) in dict.logType" :label="e.label" :value="e.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" :push="4">
                                <el-form-item label="ip" prop="title">
                                    <el-input v-model="searchForm.ip"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="开始时间" prop="title">
                                    <!--<el-input v-model="searchForm.operateDateBegin"></el-input>-->
                                    <el-date-picker style="width: 100%"
                                                    v-model="searchForm.operateDateBegin"
                                                    type="datetime"
                                                    placeholder="选择日期时间">
                                    </el-date-picker>

                                </el-form-item>
                            </el-col>
                            <el-col :span="6" :push="2">
                                <el-form-item label="结束时间" prop="title">
                                    <el-date-picker style="width: 100%"
                                                    v-model="searchForm.operateDateEnd"
                                                    type="datetime"
                                                    placeholder="选择日期时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div class="search-form-footer">
                        <el-button @click="resetSearch">重置</el-button>
                        <el-button @click="getData">搜索</el-button>
                    </div>
                </div>


                <el-table-column label="操作" slot="table-button" :width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="toSystemLogDetail(scope.row)">查看</el-link>
                    </template>
                </el-table-column>

            </EDCTable>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import EDCTable from '../../components/table/EDCTable'
    import {getSystemLog} from "@/api/system";
    import {tableHeader} from '../../utils/globalConst'
    import {SelectorDefine} from '../../utils/SelectorDefine'

    export default {

        components: {EDCTable},
        data() {
            return {
                versionSelector: [],
                tableColunm: [],
                tableData: [],
                dictFiled: ['operateType', 'logType'],
                dict: {},

                page: {
                    totalNum: 0,
                    size: 10,
                    current: 1,
                },
                searchForm: {
                    researchCode: '',
                    researchVersionCode: '',
                    researchVersionNum: '',
                    operateType: '',
                    userId: '',
                    userName: '',
                    env: this.$localData('get', "operatingEnv") == 'prod' ? '2' : '1',
                    ip: '',
                    operateDateBegin: '',
                    operateDateEnd: '',
                    logType: '1',
                }
            }
        },
        methods: {
            resetSearch() {
                this.searchForm = {
                    researchCode: this.$route.query.researchCode || '',
                    researchVersionCode: '',
                    researchVersionNum: '',
                    operateType: '',
                    userId: '',
                    userName: '',
                    env: this.$localData('get', "operatingEnv") == 'prod' ? '2' : '1',
                    ip: '',
                    operateDateBegin: '',
                    operateDateEnd: '',
                    logType: '1',
                };
                // this.$refs['searchForm'].resetFields();
                this.page.size = 10;
                this.page.current = 1;
                this.getData();
            },
            toSystemLogDetail(row) {
                console.log('toSystemLogDetail', row);
                this.$router.push({
                    name: 'SystemLogDetail',
                    query: {logId: row.pkId}
                })
            },
            handlePageChange(page) {
                this.page = page;
                this.getData();
            },
            getData() {
                let params = Object.assign(this.page, this.searchForm);

                getSystemLog(params).then(r => {
                    console.log("获取日志列表", r);

                    if (this.searchForm.logType == 2)
                        this.tableColunm = tableHeader.systemLogException;
                    else
                        this.tableColunm = tableHeader.systemLogOper;
                    this.page.totalNum = r.data.versionPage.total;
                    this.tableData = r.data.versionPage.records;
                    this.versionSelector = r.data.versionSelector;
                })
            }
        },

        created() {
            this.searchForm.researchCode = this.$route.query.researchCode
                || this.$localData('get', globalConst.RESEARCH_CODE);

            this.dict = SelectorDefine().SystemLog;
            this.tableColunm = tableHeader.systemLogOper;
            this.getData();
        },

    }
</script>

<style scoped>
    .search-form-box {

    }

    .search-form-footer {
        text-align: right;
        padding-right: 8%;
    }
</style>